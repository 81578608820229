const appNav = () => ({
  lastVisitedPage: '',
  autoHopPage: '',
  pages: [{
    label: 'Personal',
    secondarySteps: [
      {
        label: 'Contact Info',
        name: 'contactInfo',
        enabled: true,
        visited: false,
        link: '/admissions/personal/contact-info',
      },
      {
        label: 'Tell Us More',
        name: 'tellUsMore',
        enabled: true,
        visited: false,
        link: '/admissions/personal/more',
      },
      {
        label: 'Military',
        name: 'military',
        enabled: true,
        visited: false,
        link: '/admissions/personal/military',
      },
    ],
    enabled: true,
  },
  {
    label: 'Program',
    secondarySteps: [
      {
        label: 'Program Selection',
        name: 'programSelection',
        enabled: true,
        visited: false,
        link: '/admissions/program/selection',
      },
      {
        label: 'License Info',
        name: 'licenseInfo',
        enabled: true,
        visited: false,
        link: '/admissions/program/nursing',
      },
    ],
    enabled: true,
  },
  {
    label: 'Education',
    secondarySteps: [
      {
        label: 'Secondary',
        name: 'secondary',
        enabled: true,
        visited: false,
        link: '/admissions/education/secondary-education',
      },
      {
        label: 'Higher',
        name: 'higher',
        enabled: true,
        visited: false,
        link: '/admissions/education/higher-education',
      },
      {
        label: 'Expulsion Info',
        name: 'expulsionInfo',
        enabled: true,
        visited: false,
        link: '/admissions/education/expulsion-info',
      },
      {
        label: 'Exam credits',
        name: 'credits',
        enabled: true,
        visited: false,
        link: '/admissions/education/test-credits',
      },
      {
        label: 'Transcript',
        name: 'transcript',
        enabled: false,
        visited: false,
        link: '/admissions/education/transcripts',
      },
    ],
    enabled: true,
  },
  {
    label: 'Work',
    secondarySteps: [
      {
        label: 'Work History',
        name: 'work',
        enabled: true,
        visited: false,
        link: '/admissions/work',
      },
    ],
    enabled: true,
  },
  {
    label: 'Financial Plan',
    secondarySteps: [
      {
        label: 'Intro',
        name: 'financial',
        enabled: true,
        visited: false,
        link: '/admissions/financial/introduction',
      },
      {
        label: 'Military',
        name: 'financialMilitary',
        enabled: true,
        visited: false,
        link: '/admissions/financial/military',
      },
      {
        label: 'Grants',
        name: 'financialGrants',
        enabled: true,
        visited: false,
        link: '/admissions/financial/grants',
      },
      {
        label: 'Employer',
        name: 'financialEmployerBenefits',
        enabled: false,
        visited: false,
        link: '/admissions/financial/employer-benefits',
      },
      {
        label: 'Discounts',
        name: 'financialDiscounts',
        enabled: false,
        visited: false,
        link: '/admissions/financial/associations',
      },
      {
        label: 'Scholarships',
        name: 'financialScholarships',
        enabled: false,
        visited: false,
        link: '/admissions/financial/scholarships',
      },
      {
        label: 'Tribal',
        name: 'financialTribalBenefits',
        enabled: false,
        visited: false,
        link: '/admissions/financial/tribal-benefits',
      },
      {
        label: 'Funding sources',
        name: 'financialBenefits',
        enabled: true,
        visited: false,
        link: '/admissions/financial/benefits',
      },
      {
        label: 'Personal',
        name: 'financialPersonal',
        enabled: true,
        visited: false,
        link: '/admissions/financial/personal',
      },
      {
        label: 'Loan',
        name: 'financialLoans',
        enabled: true,
        visited: false,
        link: '/admissions/financial/loan',
      },
      {
        label: 'Summary',
        name: 'financialSummary',
        enabled: true,
        visited: false,
        link: '/admissions/financial/summary',
      },
      {
        label: 'Estimate',
        name: 'financialEstimate',
        enabled: false,
        visited: false,
        link: '/admissions/financial/financial-plan-estimate',
      },
    ],
    enabled: true,
  },
  {
    label: 'Manage funds',
    secondarySteps: [
      {
        label: 'Credit balance',
        name: 'manageFundsCreditBalance',
        enabled: false,
        visited: false,
        link: '/admissions/payment/balance',
      },
    ],
    enabled: false,
  },
  {
    label: 'Review',
    secondarySteps: [
      {
        label: 'Review',
        name: 'review',
        enabled: true,
        visited: false,
        link: '/admissions/review',
      },
      {
        label: 'Acknowledge',
        name: 'reviewAcknowledge',
        enabled: true,
        visited: false,
        link: '/admissions/review/acknowledgements',
      },
      {
        label: 'Sign and Submit',
        name: 'reviewSignSubmit',
        enabled: true,
        visited: false,
        link: '/admissions/review/sign-and-submit',
      },
    ],
    enabled: true,
  }],
});

export default appNav;
