export const ACTIONS = {
  REQUEST_EMPLOYMENTS: 'REQUEST_EMPLOYMENTS',
  RECEIVE_EMPLOYMENTS: 'RECEIVE_EMPLOYMENTS',
  RECEIVE_EMPLOYMENTS_ERROR: 'RECEIVE_EMPLOYMENTS_ERROR',

  REQUEST_SAVE_EMPLOYMENTS: 'REQUEST_SAVE_EMPLOYMENTS',
  RECEIVE_SAVE_EMPLOYMENTS: 'RECEIVE_SAVE_EMPLOYMENTS',
  REQUEST_SAVE_EMPLOYMENTS_ERROR: 'REQUEST_SAVE_EMPLOYMENTS_ERROR',

  REQUEST_DELETE_EMPLOYMENTS: 'REQUEST_DELETE_EMPLOYMENTS',
  RECEIVE_DELETE_EMPLOYMENTS: 'RECEIVE_DELETE_EMPLOYMENTS',
  REQUEST_DELETE_EMPLOYMENTS_ERROR: 'REQUEST_DELETE_EMPLOYMENTS_ERROR',
  CLEAR_EMPLOYMENTS_GET_STATUS: 'CLEAR_EMPLOYMENTS_GET_STATUS',

  CLEAR_EMPLOYMENTS_MODIFY_STATUS: 'CLEAR_EMPLOYMENTS_MODIFY_STATUS',

  REQUEST_MILITARY_INFO: 'REQUEST_MILITARY_INFO',
  RECEIVE_MILITARY_INFO: 'RECEIVE_MILITARY_INFO',
  RECEIVE_MILITARY_INFO_ERROR: 'RECEIVE_MILITARY_INFO_ERROR',

  CLEAR_MILITARY_MODIFY_STATUS: 'CLEAR_MILITARY_MODIFY_STATUS',

  REQUEST_PUT_PERSON_INFO: 'REQUEST_PUT_PERSON_INFO',
  RECEIVE_PUT_PERSON_INFO: 'RECEIVE_PUT_PERSON_INFO',
  REQUEST_PUT_PERSON_INFO_ERROR: 'REQUEST_PUT_PERSON_INFO_ERROR',

  CLEAR_PERSON_INFO_MODIFY_STATUS: 'CLEAR_PERSON_INFO_MODIFY_STATUS',
  CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS: 'CLEAR_PERSON_CITIZENSHIP_MODIFY_STATUS',
  CLEAR_NAME_MODIFY_STATUS: 'CLEAR_NAME_MODIFY_STATUS',
};

/* action types */
export const REQUEST_PERSON = 'REQUEST_PERSON';
export const RECEIVE_PERSON = 'RECEIVE_PERSON';
export const REQUEST_PERSON_ERROR = 'REQUEST_PERSON_ERROR';
export const REQUEST_POST_PERSON = 'REQUEST_POST_PERSON';
export const RECEIVE_POST_PERSON = 'RECEIVE_POST_PERSON';
export const REQUEST_POST_PERSON_ERROR = 'REQUEST_POST_PERSON_ERROR';
export const REQUEST_UPDATE_PERSON = 'REQUEST_UPDATE_PERSON';
export const RECEIVE_UPDATE_PERSON = 'RECEIVE_UPDATE_PERSON';
export const REQUEST_UPDATE_PERSON_ERROR = 'REQUEST_PERSON_UPDATE_ERROR';
export const REQUEST_NAMES = 'REQUEST_NAMES';
export const RECEIVE_NAMES = 'RECEIVE_NAMES';
export const REQUEST_NAMES_ERROR = 'REQUEST_NAMES_ERROR';
export const REQUEST_POST_NAME = 'REQUEST_POST_NAME';
export const RECEIVE_POST_NAME = 'RECEIVE_POST_NAME';
export const REQUEST_NAME_POST_ERROR = 'REQUEST_NAME_POST_ERROR';
export const REQUEST_NAME = 'REQUEST_NAME';
export const RECEIVE_NAME = 'RECEIVE_NAME';
export const REQUEST_NAME_ERROR = 'REQUEST_NAME_ERROR';
export const RECEIVE_OFFICIAL_NAME_ETAG = 'RECEIVE_OFFICIAL_NAME_ETAG';
export const RECEIVE_PREVIOUS_NAME_ETAG = 'RECEIVE_PREVIOUS_NAME_ETAG';
export const REQUEST_START_WORKFLOW = 'REQUEST_START_WORKFLOW';
export const RECEIVE_START_WORKFLOW = 'RECEIVE_START_WORKFLOW';
export const REQUEST_START_WORKFLOW_ERROR = 'REQUEST_START_WORKFLOW_ERROR';
export const REQUEST_PUT_PERSON_CITIZENSHIP = 'REQUEST_PUT_PERSON_CITIZENSHIP';
export const RECEIVE_PUT_PERSON_CITIZENSHIP = 'RECEIVE_PUT_PERSON_CITIZENSHIP';
export const REQUEST_PUT_PERSON_CITIZENSHIP_ERROR = 'REQUEST_PUT_PERSON_CITIZENSHIP_ERROR';
export const REQUEST_POST_PERSON_INFO = 'REQUEST_POST_PERSON_INFO';
export const RECEIVE_POST_PERSON_INFO = 'RECEIVE_POST_PERSON_INFO';
export const RECEIVE_PUT_PERSON_INFO = 'RECEIVE_PUT_PERSON_INFO';
export const REQUEST_PUT_PERSON_INFO = 'REQUEST_PUT_PERSON_INFO';
export const REQUEST_POST_PERSON_INFO_ERROR = 'REQUEST_POST_PERSON_INFO_ERROR';
export const REQUEST_PUT_PERSON_INFO_ERROR = 'REQUEST_PUT_PERSON_INFO_ERROR';
export const REQUEST_POST_PERSON_CITIZENSHIP = 'REQUEST_POST_PERSON_CITIZENSHIP';
export const RECEIVE_POST_PERSON_CITIZENSHIP = 'RECEIVE_POST_PERSON_CITIZENSHIP';
export const REQUEST_POST_PERSON_CITIZENSHIP_ERROR = 'REQUEST_POST_PERSON_CITIZENSHIP_ERROR';
export const REQUEST_PERSON_INFO = 'REQUEST_PERSON_INFO';
export const RECEIVE_PERSON_INFO = 'RECEIVE_PERSON_INFO';
export const REQUEST_PERSON_INFO_MISSING = 'REQUEST_PERSON_INFO_MISSING';
export const REQUEST_PERSON_INFO_ERROR = 'REQUEST_PERSON_INFO_ERROR';
export const REQUEST_PERSON_INFO_RESET = 'REQUEST_PERSON_INFO_RESET';
export const REQUEST_PERSON_META = 'REQUEST_PERSON_META';
export const REQUEST_PERSON_META_ERROR = 'REQUEST_PERSON_META_ERROR';
export const RECEIVE_PERSON_META = 'RECEIVE_PERSON_META';
export const REQUEST_PERSON_CITIZENSHIP = 'REQUEST_PERSON_CITIZENSHIP';
export const RECEIVE_PERSON_CITIZENSHIP = 'RECEIVE_PERSON_CITIZENSHIP';
export const REQUEST_PERSON_CITIZENSHIP_ERROR = 'REQUEST_PERSON_CITIZENSHIP_ERROR';
export const REQUEST_PERSON_CERTIFICATE = 'REQUEST_PERSON_CERTIFICATE';
export const RECEIVE_PERSON_CERTIFICATE = 'RECEIVE_PERSON_CERTIFICATE';
export const REQUEST_PERSON_CERTIFICATE_ERROR = 'REQUEST_PERSON_CERTIFICATE_ERROR';
export const REQUEST_POST_PREVIOUS_NAME = 'REQUEST_POST_PREVIOUS_NAME';
export const RECEIVE_POST_PREVIOUS_NAME = 'RECEIVE_POST_PREVIOUS_NAME';
export const RECEIVE_POST_PREVIOUS_NAME_ERROR = 'RECEIVE_POST_PREVIOUS_NAME_ERROR';
export const REQUEST_DELETE_NAME = 'REQUEST_DELETE_NAME';
export const RECEIVE_DELETE_NAME = 'RECEIVE_DELETE_NAME';
export const RECEIVE_DELETE_NAME_ERROR = 'RECEIVE_DELETE_NAME_ERROR';
export const REQUEST_PERSON_MILITARY = 'REQUEST_PERSON_MILITARY';
export const RECEIVE_PERSON_MILITARY = 'RECEIVE_PERSON_MILITARY';
export const REQUEST_PERSON_MILITARY_ERROR = 'REQUEST_PERSON_MILITARY_ERROR';
export const REQUEST_POST_PERSON_MILITARY = 'REQUEST_POST_PERSON_MILITARY';
export const RECEIVE_POST_PERSON_MILITARY = 'RECEIVE_POST_PERSON_MILITARY';
export const REQUEST_POST_PERSON_MILITARY_ERROR = 'REQUEST_POST_PERSON_MILITARY_ERROR';
export const REQUEST_PUT_PERSON_MILITARY = 'REQUEST_PUT_PERSON_MILITARY';
export const RECEIVE_PUT_PERSON_MILITARY = 'RECEIVE_PUT_PERSON_MILITARY';
export const REQUEST_PUT_PERSON_MILITARY_ERROR = 'REQUEST_PUT_PERSON_MILITARY_ERROR';
export const MULTISTEP_CREATE_ACCOUNT_START = 'MULTISTEP_CREATE_ACCOUNT_START';
export const MULTISTEP_CREATE_ACCOUNT_COMPLETION = 'MULTISTEP_CREATE_ACCOUNT_COMPLETION';
export const MULTISTEP_CREATE_ACCOUNT_ERROR = 'MULTISTEP_CREATE_ACCOUNT_ERROR';

export const REQUEST_POST_PERSON_GENERIC_INFO = 'REQUEST_POST_PERSON_GENERIC_INFO';
export const RECEIVE_POST_PERSON_GENERIC_INFO = 'RECEIVE_POST_PERSON_GENERIC_INFO';
export const RECEIVE_PUT_PERSON_GENERIC_INFO = 'RECEIVE_PUT_PERSON_GENERIC_INFO';
export const REQUEST_PUT_PERSON_GENERIC_INFO = 'REQUEST_PUT_PERSON_GENERIC_INFO';
export const REQUEST_POST_PERSON_GENERIC_INFO_ERROR = 'REQUEST_POST_PERSON_GENERIC_INFO_ERROR';
export const REQUEST_PUT_PERSON_GENERIC_INFO_ERROR = 'REQUEST_PUT_PERSON_GENERIC_INFO_ERROR';
export const REQUEST_PERSON_GENERIC_INFO = 'REQUEST_PERSON_GENERIC_INFO';
export const RECEIVE_PERSON_GENERIC_INFO = 'RECEIVE_PERSON_GENERIC_INFO';
export const REQUEST_PERSON_GENERIC_INFO_MISSING = 'REQUEST_PERSON_GENERIC_INFO_MISSING';
export const REQUEST_PERSON_GENERIC_INFO_ERROR = 'REQUEST_PERSON_GENERIC_INFO_ERROR';
