/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { UOPX_HELP_DESK_PHONE_NUMBER } from '../../../modules/admissions/constants';
import {
  ReactComponent as IconHelpSupport,
} from '../../icons/helpSupport/HelpSupportIcon.svg';
import NameHelp from './NameHelp';
import PhoneHelp from './PhoneHelp';
import EmailHelp from './EmailHelp';
import ChatHelp from '../ChatHelp';
import styles from './styles';
import { triggerAlloyTrack } from '../../../modules/vendor/alloy/AlloyUpdater';
import { handleERCallClick, handleEREmailClick } from '../../../store/helpers/commonHelper';

export default function WereHereToHelp({
  heading,
  headerClassName,
  subHeadingLabel,
  listItems,
  fullname,
  phoneNumber,
  emailAddress,
  personId,
  analyticComponent,
}) {
  const classes = styles();
  const isLogout = window.location.pathname.indexOf('/logout') !== -1;

  const handleERPhoneCallClick = () => {
    handleERCallClick(analyticComponent, phoneNumber, personId);
  };

  const handleTechCallClick = () => {
    triggerAlloyTrack('ContactClick', {
      componentName: analyticComponent,
      name: 'Technical Support',
      text: UOPX_HELP_DESK_PHONE_NUMBER,
      interactLocation: 'Content',
    }, { personId });
  };

  const handleEmailClick = () => {
    handleEREmailClick(analyticComponent, phoneNumber, personId);
  };

  const handleChatClick = () => {
    triggerAlloyTrack('Chat', {
      componentName: analyticComponent,
      text: 'Chat with us',
      interactLocation: 'Content',
    }, { personId });
  };

  return (
    <div className={clsx(classes.root, 'wereHereToHelp')}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.mainGrid}
      >
        <Grid item xs={12} md={7} className={classes.infoSection}>
          <Typography variant={heading} className={clsx(classes.heading, `${headerClassName}`)}>
            We&apos;re here to help
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12} sm={7} className="erSupport">
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h3" className={classes.h3}>
                  {subHeadingLabel}
                </Typography>
                {listItems ? (
                  <ul className="unordered-list main">
                    {listItems.map((item, index) => (
                      <li key={index}><span>{item}</span></li>
                    ))}
                  </ul>
                ) : null}
                {fullname ? (
                  <NameHelp fullname={fullname} />
                ) : null}
                {phoneNumber ? (
                  <PhoneHelp
                    id={fullname ? 'wereHereHelp_general_phone' : 'wereHereHelp_er_phone'}
                    phoneNumber={phoneNumber}
                    ariaLabel="Call enrollment representative at"
                    onClickCallback={handleERPhoneCallClick}
                  />
                ) : null}
                {emailAddress ? (
                  <EmailHelp
                    emailAddress={emailAddress}
                    ariaLabel="Email enrollment representative at"
                    onClickCallback={handleEmailClick}
                  />
                ) : null}
                {!fullname && !isLogout ? (
                  <ChatHelp
                    onClickCallback={handleChatClick}
                  />
                ) : null}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={5} className="techSupport">
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h3" className={classes.h3}>
                  Technical support is available:
                </Typography>
                <ul className="unordered-list unordered-list-mobile main">
                  <li><span>7 days a week</span></li>
                  <li><span>5 am – 1 pm MST</span></li>
                </ul>
                <PhoneHelp
                  id="wereHereHelp_tech_phone"
                  phoneNumber={UOPX_HELP_DESK_PHONE_NUMBER}
                  ariaLabel={`${UOPX_HELP_DESK_PHONE_NUMBER} is the University of Phoenix technical support number`}
                  onClickCallback={handleTechCallClick}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} className={classes.helpSupportIcon}>
          <IconHelpSupport />
        </Grid>
      </Grid>
    </div>
  );
}

WereHereToHelp.defaultProps = {
  heading: 'h2',
  headerClassName: '',
  listItems: null,
  fullname: null,
  phoneNumber: null,
  emailAddress: null,
  personId: '',
  analyticComponent: 'Application',
};

WereHereToHelp.propTypes = {
  heading: PropTypes.string,
  headerClassName: PropTypes.string,
  subHeadingLabel: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.string),
  fullname: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,
  analyticComponent: PropTypes.string,
  personId: PropTypes.string,
};
