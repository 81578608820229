import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

// =====================================================
// This component is USED with the 'Footnotes' component
// =====================================================

export default function InTextReference({
  referenceNumber,
  referenceText,
  handleClick,
}) {
  const classes = styles();

  return (
    <a
      href={`#footnote${referenceNumber}`}
      aria-describedby="footnote-label"
      id={`footnoteRef${referenceNumber}`}
      className={clsx(classes.reference, 'inTextReference')}
      onClick={() => {
        handleClick();
      }}
    >
      {referenceText}
    </a>
  );
}

InTextReference.defaultProps = {
  referenceNumber: 1,
  referenceText: '',
  handleClick: () => {},
};

InTextReference.propTypes = {
  referenceNumber: PropTypes.number,
  referenceText: PropTypes.string,
  handleClick: PropTypes.func,
};
