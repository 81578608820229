import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import {
  Visibility, VisibilityOff,
} from '@material-ui/icons';
import DisabledTooltip from '../../../tooltips/disabled-tooltip/DisabledTooltip';
import FormControlTooltip from '../../../../modules/admissions/components/form-input/form-control-tooltip/FormControlTooltip';

export const TextField = ({
  ariaDescribedBy,
  ariaLabelledBy,
  autoComplete,
  control,
  dataCy,
  customError,
  defaultValue,
  disabled,
  disableHelperText,
  disabledTooltipText,
  tooltipContextId,
  fullWidth,
  id,
  isLoading,
  label,
  name,
  onBlurCallback,
  onChangeCallback,
  placeholder,
  required,
  subHelperText,
  type,
  variant,
  className,
  dataStyle,
  tooltipSev,
  tooltipMsg,
  onPaste,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);

  return (
    <Controller
      render={({
        field: {
          onChange, onBlur, value, ref,
        },
        // Waiting for isValidating to properly return (next update?)
        fieldState: { invalid, error },
      }) => (
        <>
          {tooltipMsg === null ? (
            <MuiTextField
              data-container={customError.customErrorCondition === error?.type
              && customError.customErrorComponent ? `${id}-wrapper` : ''}
              data-style={dataStyle}
              id={id}
              name={name}
              value={value}
              disabled={disabled}
              label={label}
              placeholder={placeholder}
              fullWidth={fullWidth}
              error={invalid}
              type={showPassword ? 'text' : type}
              variant={variant}
              required={required}
              autoComplete={autoComplete}
              onFocus={() => setFocused(true)}
              onBlur={event => {
                onBlur(event);
                onBlurCallback(event);
                setFocused(false);
              }}
              onChange={event => {
                onChange(event);
                onChangeCallback(event);
              }}
              InputProps={{
                'aria-labelledby': ariaLabelledBy,
                'aria-describedby': ariaDescribedBy,
                'data-cy': dataCy,
                endAdornment: (
                  <InputAdornment position="end">
                    {disabled && (
                    <DisabledTooltip
                      placement="top"
                      id={id}
                      title={disabledTooltipText || 'This field is disabled'}
                      iconSize="small"
                      popperProps={{}}
                      tooltipContextId={tooltipContextId}
                    />
                    )}
                    {type === 'password' && (
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    )}
                    {isLoading && <CircularProgress size={24} />}
                  </InputAdornment>
                ),
              }}
              helperText={!disableHelperText && error?.message}
              inputRef={ref}
              className={className}
              onPaste={onPaste}
            />
          ) : (
            <FormControlTooltip
              tooltipSeverity={tooltipSev}
              tooltipMessage={tooltipMsg}
            >
              <MuiTextField
                data-container={customError.customErrorCondition === error?.type
                && customError.customErrorComponent ? `${id}-wrapper` : ''}
                data-style={dataStyle}
                id={id}
                name={name}
                value={value}
                disabled={disabled}
                label={label}
                placeholder={placeholder}
                fullWidth={fullWidth}
                error={invalid}
                type={showPassword ? 'text' : type}
                variant={variant}
                required={required}
                autoComplete={autoComplete}
                onFocus={() => setFocused(true)}
                onBlur={event => {
                  onBlur(event);
                  onBlurCallback(event);
                  setFocused(false);
                }}
                onChange={event => {
                  onChange(event);
                  onChangeCallback(event);
                }}
                InputProps={{
                  'aria-labelledby': ariaLabelledBy,
                  'aria-describedby': ariaDescribedBy,
                  'data-cy': dataCy,
                  endAdornment: (
                    <InputAdornment position="end">
                      {disabled && (
                      <DisabledTooltip
                        placement="top"
                        id={id}
                        title={disabledTooltipText || 'This field is disabled'}
                        iconSize="small"
                        popperProps={{}}
                        tooltipContextId={tooltipContextId}
                      />
                      )}
                      {type === 'password' && (
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      )}
                      {isLoading && <CircularProgress size={24} />}
                    </InputAdornment>
                  ),
                }}
                helperText={!disableHelperText && error?.message}
                inputRef={ref}
                className={className}
              />
            </FormControlTooltip>
          )}
          {customError.customErrorCondition === error?.type
              && customError.customErrorComponent && (
                <customError.customErrorComponent />
          )}
          {focused && subHelperText}
        </>
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

TextField.propTypes = {
  ariaDescribedBy: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  autoComplete: PropTypes.oneOf([
    'on',
    'off',
    'name',
    'email',
    'username',
    'new-password',
    'current-password',
    'street-address',
    'address-line1',
    'country',
    'country-name',
    'postal-code',
    'bday',
    'tel',
  ]),
  control: PropTypes.shape().isRequired,
  customError: PropTypes.shape({
    customErrorCondition: PropTypes.string,
    customErrorComponent: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.func,
    ]),
  }),
  dataCy: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  disableHelperText: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  tooltipContextId: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlurCallback: PropTypes.func,
  onChangeCallback: PropTypes.func,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  subHelperText: PropTypes.element,
  type: PropTypes.oneOf(['text', 'password', 'number', 'tel', 'email', 'date']),
  variant: PropTypes.string,
  className: PropTypes.string,
  dataStyle: PropTypes.bool,
  tooltipSev: PropTypes.string,
  tooltipMsg: PropTypes.element,
};

TextField.defaultProps = {
  ariaDescribedBy: '',
  ariaLabelledBy: '',
  autoComplete: 'on',
  customError: {},
  dataCy: '',
  defaultValue: '',
  disabled: false,
  disableHelperText: false,
  disabledTooltipText: '',
  tooltipContextId: '',
  fullWidth: true,
  id: '',
  isLoading: false,
  label: '',
  onBlurCallback: () => {},
  onChangeCallback: () => {},
  onPaste: () => {},
  placeholder: '',
  required: false,
  subHelperText: <></>,
  type: 'text',
  variant: 'outlined',
  className: '',
  dataStyle: false,
  tooltipSev: 'info',
  tooltipMsg: null,
};

export default TextField;
