/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
  Typography, Button, Grid,
  Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './styles';

export default function ExpandAllAccordion({
  mainHeading,
  heading,
  header,
  itemList,
}) {
  const classes = styles();
  const [expandAll, setExpandAll] = useState(false);
  const [expanded, setExpanded] = useState('' || false);

  const handleExpandCollapseClick = () => {
    setExpandAll(prevState => !prevState);
  };

  const handleChange = (isExpanded, accordion) => {
    setExpanded(isExpanded ? accordion : false);
  };

  return (
    <Grid
      container
      className={clsx(classes.root, 'expandCollapseAll')}
    >
      <Grid item xs={12} sm={4} className={clsx(classes.collapseAll, 'gridFirst')}>
        <Typography variant={mainHeading} className="expandAllHeader">{header}</Typography>
        <Button
          className={classes.expandAllBtn}
          onClick={handleExpandCollapseClick}
          disableRipple
        >
          {expandAll ? (
            <>
              <RemoveIcon />
              {' '}
              <span className="expandText">Collapse all</span>
            </>
          ) : (
            <>
              <AddIcon />
              {' '}
              <span className="expandText">Expand all</span>
            </>
          )}
        </Button>
      </Grid>
      <Grid item xs={12} sm={8} className={clsx(classes.allAccordions, 'gridLast')}>
        {itemList.map(accInfo => (
          <Accordion
            expanded={expandAll || expanded === `${accInfo.index}`}
            onChange={(event, isExpanded) => handleChange(isExpanded, `${accInfo.index}`)}
            className="expandAllAccordion"
            elevation={0}
            key={accInfo.index}
          >
            <AccordionSummary
              expandIcon={expandAll || expanded === `${accInfo.index}`
                ? <RemoveIcon fontSize="large" />
                : <AddIcon fontSize="large" />}
              aria-controls={accInfo.ariaControls}
              id={accInfo.id}
            >
              <Typography variant={heading} className="expandAllAccordionHeader">
                {accInfo.header}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{accInfo.paragraph}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}

ExpandAllAccordion.defaultProps = {
  mainHeading: 'h2',
  heading: 'h3',
};

ExpandAllAccordion.propTypes = {
  mainHeading: PropTypes.string,
  heading: PropTypes.string,
  header: PropTypes.string.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
};
