import { getDatesTodayAndFutureFromNow } from './helpers';

export default class ProgramServiceBase {
  constructor(client, apiVersion) {
    this.client = client;
    this.baseEndpointUrl = `/api/programs/${apiVersion}`;
    this.getProgramOfferingsByZipCodeAndState = this.getProgramOfferingsByZipCodeAndState.bind(
      this,
    );
    this.getProgramOfferingStartDates = this.getProgramOfferingStartDates.bind(this);
    this.getProgramOffering = this.getProgramOffering.bind(this);
    this.getProgramTemplateAsync = this.getProgramTemplateAsync.bind(this);
    this.getProgramTemplateById = this.getProgramTemplateById.bind(this);
    this.getProgramTemplateByProgramVersionOidAsync = this.getProgramTemplateByProgramVersionOidAsync
      .bind(this);
    // this.getProgramEligibilityByProgramIdVerModZip = this.getProgramEligibilityByProgramIdVerModZip(
    //   this,
    // );
  }

  async getProgramOfferingsByZipCodeAndState(zipCode, stateCode, modality, siteOid) {
    const { today: effectiveFrom, future: effectiveTo } = getDatesTodayAndFutureFromNow(
      24,
      'months',
    );
    const url = `${this.baseEndpointUrl}/programOfferings?$filter=zipcode eq ${zipCode} and stdat eq ${effectiveFrom} and endtd eq ${effectiveTo} and state eq ${stateCode}`;
    return this.client.get(url);
  }

  async getProgramOfferingStartDates(programId, version, siteOid, modality) {
    const { today: fromDate, future: toDate } = getDatesTodayAndFutureFromNow(6, 'months');
    const url = `${this.baseEndpointUrl}/programDetail/programOfferingStartdates?programId=${programId}&version=${version}&offeredAtLocationid=${siteOid}&fromDate=${fromDate}&toDate=${toDate}&offerType=${modality}`;
    return this.client.get(url);
  }

  async getProgramOffering(programOfferingId) {
    const url = `${this.baseEndpointUrl}/programOffering/id?id=${programOfferingId}`;
    return this.client.get(url);
  }

  async getProgramTemplateById(programTemplateId) {
    const url = `${this.baseEndpointUrl}/templates/${programTemplateId}`;
    return this.client.get(url);
  }

  async getProgramTemplateAsync(programId, version) {
    const url = `${this.baseEndpointUrl}/programDetail?programId=${programId}&version=${version}`;
    return this.client.get(url);
  }

  async getProgramTemplateByProgramVersionOidAsync(programVersionOid) {
    const url = `${this.baseEndpointUrl}/programDetail?programVersionOid=${programVersionOid}`;
    return this.client.get(url);
  }

  getProgramEligibilityByProgramIdVerModZip = (programId, version, modality, state, zipCode) => this.client.get(
    `${this.baseEndpointUrl}/programEligibility?$filter=programId eq ${programId} version eq ${version} modality eq ${modality} state eq ${state} zipCode eq ${zipCode}`,
  );

  getProgramEligibilityByProgramIdZip = (programId, state, zipCode) => this.client.get(
    `${this.baseEndpointUrl}/programEligibility?$filter=programId eq ${programId} state eq ${state} zipCode eq ${zipCode}`,
  );
}
