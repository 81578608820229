import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './styles';

// ===========================================================
// This component is USED with the 'InTextReference' component
// ===========================================================

export default function Footnotes({
  headingADA,
  footnotes,
}) {
  const classes = styles();

  return (
    <div className={clsx(classes.fnReferences, 'footnotesWrapper')}>
      <Typography
        variant={headingADA}
        className="sr-only"
        id="footnote-label"
      >
        Footnotes
      </Typography>
      <ol className={classes.footnotes}>
        {footnotes.map((footnote, i) => (
          <Typography
            className={clsx(classes.asterisk, 'ref')}
            component="li"
            id={`footnote${i + 1}`}
            key={i.toString()}
          >
            {footnote.title ? (
              <>
                <strong>{footnote.title}</strong>
                <br />
              </>
            ) : null}
            {footnote.text}
            {footnote.list ? (
              <ul className={clsx(classes.footnoteList, 'tight unordered-list')}>
                {footnote.list.map((item, index) => (
                  <li key={index.toString()}>{item}</li>
                ))}
              </ul>
            ) : null}
            {' '}
            <a
              href={`#footnoteRef${i + 1}`}
              aria-label="Back to content"
              className={classes.backToContent}
            >
              ↩
            </a>
          </Typography>
        ))}
      </ol>
    </div>
  );
}

Footnotes.defaultProps = {
  headingADA: 'h2',
  footnotes: [],
};

Footnotes.propTypes = {
  headingADA: PropTypes.string,
  footnotes: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.string),
  })),
};
