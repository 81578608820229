import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(-1.5),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    '& h2.expandAllHeader': {
      fontSize: '2rem!important',
      lineHeight: 1.2,
      fontWeight: '300!important',
      margin: '0!important',
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.4rem!important',
        paddingRight: theme.spacing(2),
      },
    },
  },
  expandAllBtn: {
    fontWeight: 400,
    padding: 0,
    marginTop: theme.spacing(1.6),
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      '& .MuiButton-label .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiButton-label .MuiSvgIcon-root': {
      color: theme.palette.common.blue,
    },
    '& .expandText': {
      textDecoration: 'none',
    },
  },
}));
