import retryCallIfNeeded from '../../../restCalls';

const walletEndpoint = '/api/wallet/v1';

export default function walletService(client) {
  // ###
  // SSN
  // ###
  const getSsnByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${walletEndpoint}/wallets/?personId=${personId}`));
  const deleteSsnByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .delete(`${walletEndpoint}/wallets/?personId=${personId}`));
  const postSsnByPersonIdAsync = async (ssn, personId) => {
    const payload = { ssn, personId };
    return retryCallIfNeeded(client, () => client
      .post(`${walletEndpoint}/wallets`, payload));
  };
  const putSsnByPersonIdAsync = async (ssn, personId) => {
    const payload = { ssn, personId };
    return retryCallIfNeeded(client, () => client
      .put(`${walletEndpoint}/wallets`, payload));
  };
  const getFullSsnByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${walletEndpoint}/wallets?digits=9&personId=${personId}`));

  // ##############
  // CREDIT BALANCE
  // ##############
  const getCreditBalanceByPersonIdAsync = async personId => retryCallIfNeeded(client, () => client
    .get(`${walletEndpoint}/creditbalance/?personId=${personId}`));

  const deleteCreditBalanceByPersonIdAsync = async personId => (
    retryCallIfNeeded(client, () => client
      .delete(`${walletEndpoint}/creditbalance/?personId=${personId}`))
  );

  const postCreditBalanceByPersonIdAsync = async (creditBalance, personId) => {
    const payload = { personId, ...creditBalance };
    return retryCallIfNeeded(client, () => client
      .post(`${walletEndpoint}/creditbalance`, payload));
  };

  const putCreditBalanceByPersonIdAsync = async (creditBalance, personId) => {
    const payload = { personId, ...creditBalance };
    return retryCallIfNeeded(client, () => client
      .put(`${walletEndpoint}/creditbalance`, payload));
  };

  return {
    // SSN
    getSsnByPersonIdAsync,
    postSsnByPersonIdAsync,
    putSsnByPersonIdAsync,
    deleteSsnByPersonIdAsync,
    getFullSsnByPersonIdAsync,
    // CREDIT BALANCE
    getCreditBalanceByPersonIdAsync,
    postCreditBalanceByPersonIdAsync,
    putCreditBalanceByPersonIdAsync,
    deleteCreditBalanceByPersonIdAsync,
  };
}
