import {
  RESET_PROGRAM_OFFERING,
  REQUEST_PROGRAM_OFFERING,
  RECEIVE_PROGRAM_OFFERING,
  REQUEST_PROGRAM_OFFERING_ERROR,
  RESET_PROGRAM_START_DATES,
  REQUEST_PROGRAM_START_DATES,
  RECEIVE_PROGRAM_START_DATES,
  REQUEST_PROGRAM_START_DATES_ERROR,
  REQUEST_PROGRAM_OFFERING_ITEM,
  RECEIVE_PROGRAM_OFFERING_ITEM,
  REQUEST_PROGRAM_OFFERING_ITEM_ERROR,
  CLEAR_PROGRAM_OFFERING_ITEM_GET_STATUS,
  REQUEST_PROGRAM_TEMPLATE,
  RECEIVE_PROGRAM_TEMPLATE,
  REQUEST_PROGRAM_TEMPLATE_ERROR,
  REQUEST_PROGRAM_TEMPLATE_BY_ID,
  RECEIVE_PROGRAM_TEMPLATE_BY_ID,
  REQUEST_PROGRAM_TEMPLATE_BY_ID_ERROR,
  RECEIVE_BLACKBOARD_COLLABORATION,
} from './types';
import STATUS from '../../globalStatuses';
import { errorToMessage } from '../../../errorMessaging';

function programofferingReducer(
  state = {
    programOffering: [],
    programOfferingDates: [],
    programOfferingStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
    },
    programOfferingDatesStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
    },
    programOfferingItem: {},
    programOfferingItemStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
    },
    programTemplateItem: {},
    programTemplateItemStatus: {
      getStatus: STATUS.UNFETCHED,
      modifyStatus: STATUS.UNFETCHED,
    },
  },
  action,
) {
  switch (action.type) {
    // RESET Program Offering
    case RESET_PROGRAM_OFFERING:
      return {
        ...state,
        programOffering: [],
        programOfferingStatus: {
          ...state.programOfferingStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };

    // GET Program Offering
    case REQUEST_PROGRAM_OFFERING:
      return {
        ...state,
        programOffering: [],
        programOfferingStatus: {
          ...state.programOfferingStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };
    case RECEIVE_PROGRAM_OFFERING:
      return {
        ...state,
        programOffering: action.programOffering,
        programOfferingStatus: {
          ...state.programOfferingStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PROGRAM_OFFERING_ERROR:
      return {
        ...state,
        programOffering: action.programOffering,
        error: errorToMessage(action.error),
        programOfferingStatus: {
          ...state.programOfferingStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    // GET Program Offering
    case REQUEST_PROGRAM_OFFERING_ITEM:
      return {
        ...state,
        programOfferingItem: {},
        programOfferingItemStatus: {
          ...state.programOfferingItemStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };
    case RECEIVE_PROGRAM_OFFERING_ITEM:
      return {
        ...state,
        programOfferingItem: action.programOfferingItem,
        programOfferingItemStatus: {
          ...state.programOfferingItemStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PROGRAM_OFFERING_ITEM_ERROR:
      return {
        ...state,
        programOfferingItem: action.programOfferingItem,
        error: errorToMessage(action.error),
        programOfferingItemStatus: {
          ...state.programOfferingItemStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };
    case CLEAR_PROGRAM_OFFERING_ITEM_GET_STATUS:
      return {
        ...state,
        programOfferingItemStatus: {
          ...state.programOfferingItemStatus,
          getStatus: STATUS.UNFETCHED,
          errorData: null,
        },
      };

    // RESET Program Start Dates
    case RESET_PROGRAM_START_DATES:
      return {
        ...state,
        programOfferingDates: [],
        programOfferingDatesStatus: {
          ...state.programOfferingDatesStatus,
          getStatus: STATUS.UNFETCHED,
        },
      };

    // GET Program Start Dates
    case REQUEST_PROGRAM_START_DATES:
      return {
        ...state,
        programOfferingDatesStatus: {
          ...state.programOfferingDatesStatus,
          getStatus: STATUS.FETCHING,
          errorData: null,
        },
      };
    case RECEIVE_PROGRAM_START_DATES:
      return {
        ...state,
        programOfferingDates: action.programOfferingDates,
        programOfferingDatesStatus: {
          ...state.programOfferingDatesStatus,
          getStatus: STATUS.FETCHED,
        },
      };
    case REQUEST_PROGRAM_START_DATES_ERROR:
      return {
        ...state,
        programOfferingDates: [],
        error: errorToMessage(action.error),
        programOfferingDatesStatus: {
          ...state.programOfferingDatesStatus,
          getStatus: STATUS.ERROR,
          errorData: action.error,
        },
      };

    case REQUEST_PROGRAM_TEMPLATE:
      return {
        ...state,
        programTemplateMap: {
          ...state.programTemplateMap,
          [`${action.programId}_${action.version}`]: {
            programTemplate: {},
            getStatus: STATUS.FETCHING,
            errorData: null,
          },
        },
      };
    case RECEIVE_PROGRAM_TEMPLATE:
      return {
        ...state,
        programTemplateMap: {
          ...state.programTemplateMap,
          [`${action.programId}_${action.version}`]: {
            programTemplate: action.programTemplate,
            getStatus: STATUS.FETCHED,
            errorData: null,
          },
        },
      };
    case REQUEST_PROGRAM_TEMPLATE_ERROR:
      return {
        ...state,
        error: errorToMessage(action.error),
        programTemplateMap: {
          ...state.programTemplateMap,
          [`${action.programId}_${action.version}`]: {
            programTemplate: {},
            getStatus: STATUS.ERROR,
            errorData: action.error,
          },
        },
      };
      case REQUEST_PROGRAM_TEMPLATE_BY_ID:
        return {
          ...state,
          programTemplateItem: {},
          programTemplateItemStatus: {
            ...state.programTemplateItemStatus,
            getStatus: STATUS.FETCHING,
            errorData: null,
          },
        };
      case RECEIVE_PROGRAM_TEMPLATE_BY_ID:
        return {
          ...state,
          programTemplateItem: action.programTemplate,
          programTemplateItemStatus: {
            ...state.programTemplateItemStatus,
            getStatus: STATUS.FETCHED,
          },
        };
      case REQUEST_PROGRAM_TEMPLATE_BY_ID_ERROR:
        return {
          ...state,
          programTemplateItem: {},
          error: errorToMessage(action.error),
          programTemplateItemStatus: {
            ...state.programTemplateItemStatus,
            getStatus: STATUS.ERROR,
            errorData: action.error,
          },
        };
    case RECEIVE_BLACKBOARD_COLLABORATION:
      return {
        ...state,
        blackboardCollaboration: action.bbCollaboration,
      };
    default:
      return state;
  }
}

export default programofferingReducer;
