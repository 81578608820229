import ProgramServiceBase from '../programBase';
import { getDatesTodayAndFutureFromNow } from '../helpers';
import retryCallIfNeeded from '../../../restCalls';
import featureFlags from '../../../store/featureFlags';
import defaultFeatureFlags from '../../../store/defaultFeatureFlags';

// TODO: Refactor to use the base more than constant overrides
class ProgramServiceV3 extends ProgramServiceBase {
  constructor(client) {
    super(client, 'v3');
  }

  async getProgramOfferingsByZipCodeAndState(zipCode, stateCode, modality, siteOid) {
    const { today: effectiveFrom, future: effectiveTo } = getDatesTodayAndFutureFromNow(
      24,
      'months',
    );
    let radius = 150;
    let url = `${this.baseEndpointUrl}/programEligibility?$filter=zipcode eq ${zipCode} and appstartdt eq ${effectiveFrom} and appenddt eq ${effectiveFrom} and state eq ${stateCode} and miles eq ${radius}`;
    if (siteOid) {
      radius = 300;
      url = `${this.baseEndpointUrl}/programEligibility?$filter=zipcode eq ${zipCode} and appstartdt eq ${effectiveFrom} and appenddt eq ${effectiveFrom} and state eq ${stateCode} and miles eq ${radius} and siteOid eq ${siteOid}`;
    }
    //  else {
    //     url = `${this.baseEndpointUrl}/programEligibility?$filter=zipcode eq ${zipCode} and appstartdt eq ${effectiveFrom} and appenddt eq ${effectiveTo} and state eq ${stateCode} and miles eq ${radius}`;
    // }
    return retryCallIfNeeded(this.client, () => this.client.get(url));
  }

  async getProgramOfferingStartDates(programId, version, siteOid, modality) {
    const { today: fromDate, future: toDate } = getDatesTodayAndFutureFromNow(6, 'months');
    const modalityUpdated = modality.toUpperCase() === 'GROUND' ? 'Campus' : modality;
    const url = `${this.baseEndpointUrl}/programOfferingStartdates?programId=${programId}&version=${version}&offeredAtLocationid=${siteOid}&fromDate=${fromDate}&toDate=${toDate}&offerType=${modalityUpdated}&restrictDataTo=Learningcenter`;
    return retryCallIfNeeded(this.client, () => this.client.get(url));
  }

  async getProgramOffering(programOfferingId) {
    const url = `${this.baseEndpointUrl}/programEligibility/${programOfferingId}`;
    return this.client.get(url);
  }

  async getProgramTemplateById(programTemplateId) {
    const url = `${this.baseEndpointUrl}/templates/${programTemplateId}`;
    return this.client.get(url);
  }

  async getProgramTemplateAsync(programId, version) {
    const url = `${this.baseEndpointUrl}/templates?$filter=programId eq ${programId} and version eq ${version}`;
    return this.client.get(url);
  }

  async getProgramTemplateByProgramVersionOidAsync(programVersionOid) {
    const url = `${this.baseEndpointUrl}/templates?$filter=programVersionOid eq ${programVersionOid}`;
    return this.client.get(url);
  }

  getProgramEligibilityByProgramIdVerModZip = (programId, version, modality, state, zipCode) => retryCallIfNeeded(this.client, () => this.client.get(
    `${this.baseEndpointUrl}/programEligibility?$filter=programId eq "${programId}" version eq "${version}" modality eq "${modality}" state eq ${state} zipCode eq ${zipCode}`,
  ));

  getProgramEligibilityByProgramIdZip = (programId, state, zipCode) => retryCallIfNeeded(this.client, () => this.client.get(
    `${this.baseEndpointUrl}/programEligibility?$filter=programId eq "${programId}" state eq ${state} zipCode eq ${zipCode}`,
  ));
}

export default function ProgramService(client) {
  const serviceInstance = new ProgramServiceV3(client);

  return {
    getProgramOfferingsByZipCodeAndState: serviceInstance.getProgramOfferingsByZipCodeAndState,
    getProgramOfferingStartDates: serviceInstance.getProgramOfferingStartDates,
    getProgramOffering: serviceInstance.getProgramOffering,
    getProgramTemplateById: serviceInstance.getProgramTemplateById,
    getProgramTemplateAsync: serviceInstance.getProgramTemplateAsync,
    getProgramTemplateByProgramVersionOidAsync:
      serviceInstance.getProgramTemplateByProgramVersionOidAsync,
    getProgramEligibilityByProgramIdVerModZip:
      serviceInstance.getProgramEligibilityByProgramIdVerModZip,
    getProgramEligibilityByProgramIdZip:
      serviceInstance.getProgramEligibilityByProgramIdZip,
  };
}
