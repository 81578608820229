/* action types */
export const RESET_PROGRAM_OFFERING = 'RESET_PROGRAM_OFFERING';
export const REQUEST_PROGRAM_OFFERING = 'REQUEST_PROGRAM_OFFERING';
export const RECEIVE_PROGRAM_OFFERING = 'RECEIVE_PROGRAM_OFFERING';
export const REQUEST_PROGRAM_OFFERING_ERROR = 'REQUEST_PROGRAM_OFFERING_ERROR';

export const RESET_PROGRAM_START_DATES = 'RESET_PROGRAM_START_DATES';
export const REQUEST_PROGRAM_START_DATES = 'REQUEST_PROGRAM_START_DATES';
export const RECEIVE_PROGRAM_START_DATES = 'RECEIVE_PROGRAM_START_DATES';
export const REQUEST_PROGRAM_START_DATES_ERROR = 'REQUEST_PROGRAM_START_DATES_ERROR';

export const REQUEST_PROGRAM_OFFERING_ITEM = 'REQUEST_PROGRAM_OFFERING_ITEM';
export const RECEIVE_PROGRAM_OFFERING_ITEM = 'RECEIVE_PROGRAM_OFFERING_ITEM';
export const REQUEST_PROGRAM_OFFERING_ITEM_ERROR = 'REQUEST_PROGRAM_OFFERING_ITEM_ERROR';
export const CLEAR_PROGRAM_OFFERING_ITEM_GET_STATUS = 'CLEAR_PROGRAM_OFFERING_ITEM_GET_STATUS';

export const RESET_CAMPUS_LIST = 'RESET_CAMPUS_LIST';
export const REQUEST_CAMPUS_LIST = 'REQUEST_CAMPUS_LIST';
export const RECEIVE_CAMPUS_LIST = 'RECEIVE_CAMPUS_LIST';
export const REQUEST_CAMPUS_LIST_ERROR = 'REQUEST_CAMPUS_LIST_ERROR';

export const REQUEST_PROGRAM_TEMPLATE = 'REQUEST_PROGRAM_TEMPLATE';
export const RECEIVE_PROGRAM_TEMPLATE = 'RECEIVE_PROGRAM_TEMPLATE';
export const REQUEST_PROGRAM_TEMPLATE_ERROR = 'REQUEST_PROGRAM_TEMPLATE_ERROR';

export const REQUEST_PROGRAM_TEMPLATE_BY_ID = 'REQUEST_PROGRAM_TEMPLATE_BY_ID';
export const RECEIVE_PROGRAM_TEMPLATE_BY_ID = 'RECEIVE_PROGRAM_TEMPLATE_BY_ID';
export const REQUEST_PROGRAM_TEMPLATE_BY_ID_ERROR = 'REQUEST_PROGRAM_TEMPLATE_BY_ID_ERROR';

export const RECEIVE_BLACKBOARD_COLLABORATION = 'RECEIVE_BLACKBOARD_COLLABORATION';
