import { ACTIONS, STATUS, TYPES } from './types';
import GLOBALTYPES from '../../globalStatuses';

// TODO: Refactor to use statuses consistent with the rest of the app.
export default function document(
  state = {
    agreementDocs: [],
    agreementDocsStatus: STATUS.AGREEMENT_DOCS_UNFETCHED,
    generalAgreementDocs: [],
    generalAgreementDocsStatus: STATUS.GENERAL_AGREEMENT_DOCS_UNFETCHED,
    agreementPackage: {},
    agreementPackageStatus: {
      getStatus: GLOBALTYPES.UNFETCHED,
      errorData: null,
    },
    transcriptDocument: null,
    criminalConvictionDoc: {},
    criminalConvictionDocStatus: STATUS.CRIMINAL_CONVICTION_DOC_UNFETCHED,
    newStudentChecklistDoc: {},
    newStudentChecklistDocStatus: STATUS.NEW_STUDENT_CHECKLIST_DOC_UNFETCHED,
    maAcknowledgementDoc: {},
    maAcknowledgementStatus: STATUS.MA_DISCLOSURE_DOC_UNFETCHED,
    caAcknowledgementPfsDoc: {},
    caAcknowledgementPfsStatus: STATUS.CA_PERFORMANCE_FACTSHEET_DOC_UNFETCHED,
    getAgreementNotification: {},
    getAgreementNotificationStatus: GLOBALTYPES.UNFETCHED,
    postEsigningUrl: {},
    postEsigningUrlStatus: GLOBALTYPES.UNFETCHED,
    getEsignNotification: {},
    getEsignNotificationStatus: GLOBALTYPES.UNFETCHED,
    esignTranscriptTask: {},
    esignTranscriptTaskStatus: {
      getStatus: GLOBALTYPES.UNFETCHED,
    },
    postSignDocumentStatus: GLOBALTYPES.UNFETCHED,
    postSignDocument: {},
    financialPlanSummary: {},
    financialPlanSummaryStatus: {
      getStatus: GLOBALTYPES.UNFETCHED,
    },
    financialPlanSummaryDownloaded: false,
    financialPlanSummaryDownloadedStatus: {
      getStatus: GLOBALTYPES.UNFETCHED,
    },
  },
  { type, payload },
) {
  switch (type) {
    case ACTIONS.REQUEST_DGSP_PACKETS_SUPPORTED:
      return {
        ...state,
        dgspPacketsSupportedStatus: GLOBALTYPES.FETCHING,
      };
    case ACTIONS.RECEIVE_DGSP_PACKETS_SUPPORTED:
      return {
        ...state,
        dgspPacketsSupportedStatus: GLOBALTYPES.FETCHED,
        dgspPacketsSupported: payload.dgspPacketsSupported,
      };
    case ACTIONS.RECEIVE_DGSP_PACKETS_SUPPORTED_ERROR:
      return {
        ...state,
        dgspPacketsSupportedStatus: GLOBALTYPES.ERROR,
        // Setting this to false if we're recieving errors from DGSP
        dgspPacketsSupported: false,
        error: payload.error,
      };
    case ACTIONS.REQUEST_AGREEMENT_DOCS:
      return { ...state, agreementDocsStatus: STATUS.AGREEMENT_DOCS_FETCHING };
    case ACTIONS.RECEIVE_AGREEMENT_DOCS:
      return {
        ...state,
        agreementDocs: payload.agreementDocs,
        agreementDocsStatus: STATUS.AGREEMENT_DOCS_FETCHED,
      };
    case ACTIONS.RECEIVE_AGREEMENT_DOCS_ERROR:
      return {
        ...state,
        error: payload.error,
        agreementDocsStatus: STATUS.AGREEMENT_DOCS_ERROR,
      };
    case ACTIONS.REQUEST_GENERAL_AGREEMENT_DOCS:
      return {
        ...state,
        generalAgreementDocsStatus: STATUS.GENERAL_AGREEMENT_DOCS_FETCHING,
      };
    case ACTIONS.RECEIVE_GENERAL_AGREEMENT_DOCS:
      return {
        ...state,
        generalAgreementDocs: payload.generalAgreementDocs,
        generalAgreementDocsStatus: STATUS.GENERAL_AGREEMENT_DOCS_FETCHED,
      };
    case ACTIONS.RECEIVE_GENERAL_AGREEMENT_DOCS_ERROR:
      return {
        ...state,
        error: payload.error,
        generalAgreementDocsStatus: STATUS.GENERAL_AGREEMENT_DOCS_ERROR,
      };
    case ACTIONS.REQUEST_AGREEMENT_PACKAGE:
      return {
        ...state,
        agreementPackageStatus: {
          ...state.agreementPackageStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_AGREEMENT_PACKAGE:
      return {
        ...state,
        agreementPackage: payload.agreementPackage,
        agreementPackageStatus: {
          ...state.agreementPackageStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_AGREEMENT_PACKAGE_ERROR:
      return {
        ...state,
        error: payload.error,
        agreementPackageStatus: {
          ...state.agreementPackageStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };
    // ****** For post sign package *******
    case ACTIONS.REQUEST_POST_SIGN_PACKAGE:
      return {
        ...state,
        postSignPackageStatus: {
          ...state.postSignPackageStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_POST_SIGN_PACKAGE:
      return {
        ...state,
        postSignPackage: payload.postSignPackage,
        postSignPackageStatus: {
          ...state.postSignPackageStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_POST_SIGN_PACKAGE_ERROR:
      return {
        ...state,
        error: payload.error,
        postSignPackageStatus: {
          ...state.postSignPackageStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };
    // ****** FOR Criminal conviction doc *******
    case ACTIONS.REQUEST_CRIMINAL_CONVICTION_DOC:
      return {
        ...state,
        criminalConvictionDocStatus: {
          ...state.criminalConvictionDocStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CRIMINAL_CONVICTION_DOC:
      return {
        ...state,
        criminalConvictionDoc: payload.criminalConvictionDoc,
        criminalConvictionDocStatus: {
          ...state.criminalConvictionDocStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_CRIMINAL_CONVICTION_DOC_ERROR:
      return {
        ...state,
        error: payload.error,
        criminalConvictionDocStatus: {
          ...state.criminalConvictionDocStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };

    // ****** FOR new student checklist doc *******
    case ACTIONS.REQUEST_NEW_STUDENT_CHECKLIST_DOC:
      return {
        ...state,
        newStudentChecklistDocStatus: {
          ...state.newStudentChecklistDocStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_NEW_STUDENT_CHECKLIST_DOC:
      return {
        ...state,
        newStudentChecklistDoc: payload.newStudentChecklistDoc,
        newStudentChecklistDocStatus: {
          ...state.newStudentChecklistDocStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_NEW_STUDENT_CHECKLIST_DOC_ERROR:
      return {
        ...state,
        error: payload.error,
        newStudentChecklistDocStatus: {
          ...state.newStudentChecklistDocStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };

    // *************
    // ****** FOR Massachusetts program disclosure doc *******
    case ACTIONS.REQUEST_MA_DISCLOSURE_DOC:
      return {
        ...state,
        maAcknowledgementStatus: {
          ...state.maAcknowledgementStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_MA_DISCLOSURE_DOC:
      return {
        ...state,
        maAcknowledgementDoc: payload.maAcknowledgementDoc,
        maAcknowledgementStatus: {
          ...state.maAcknowledgementStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_MA_DISCLOSURE_DOC_ERROR:
      return {
        ...state,
        error: payload.error,
        maAcknowledgementStatus: {
          ...state.maAcknowledgementStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };
    // ****** FOR California Performance Fact Sheet doc *******
    case ACTIONS.REQUEST_CA_PERFORMANCE_FACTSHEET_DOC:
      return {
        ...state,
        caAcknowledgementPfsStatus: {
          ...state.caAcknowledgementPfsStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_CA_PERFORMANCE_FACTSHEET_DOC:
      return {
        ...state,
        caAcknowledgementPfsDoc: payload.caAcknowledgementPfsDoc,
        caAcknowledgementPfsStatus: {
          ...state.caAcknowledgementPfsStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_CA_PERFORMANCE_FACTSHEET_DOC_ERROR:
      return {
        ...state,
        error: payload.error,
        caAcknowledgementPfsStatus: {
          ...state.caAcknowledgementPfsStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };

    // *************
    case TYPES.REQUEST_POST_DOCUMENTS:
      return {
        ...state,
        transcriptStatus: GLOBALTYPES.FETCHING,
      };
    case TYPES.RECEIVE_POST_DOCUMENTS:
      return {
        ...state,
        transcriptDocument: payload.data,
        transcriptStatus: GLOBALTYPES.FETCHED,
      };
    case TYPES.ERROR_POST_DOCUMENTS:
      return {
        ...state,
        transcriptDocument: payload.error,
        transcriptStatus: GLOBALTYPES.ERROR,
      };

    // POST Agreement Sign Status switch cases for ACTIONS
    case ACTIONS.REQUEST_POST_AGREEMENT:
      return {
        ...state,
        postAgreementSignStatus: STATUS.POST_AGREEMENT_FETCHING,
      };
    case ACTIONS.RECEIVE_POST_AGREEMENT:
      return {
        ...state,
        postAgreementSign: payload.agreementSign,
        postAgreementSignStatus: STATUS.POST_AGREEMENT_FETCHED,
      };
    case ACTIONS.RECEIVE_POST_AGREEMENT_ERROR:
      return {
        ...state,
        postAgreementSign: payload.error,
        postAgreementSignStatus: STATUS.POST_AGREEMENT_ERROR,
      };

    // Upload documents to SAT switch cases for ACTIONS
    case ACTIONS.REQUEST_UPLOAD_DOCUMENTS_SAT:
      return {
        ...state,
        uploadDocumentsStatus: STATUS.UPLOAD_DOCUMENTS_FETCHING,
      };
    case ACTIONS.RECEIVE_UPLOAD_DOCUMENTS_SAT:
      return {
        ...state,
        uploadDocSAT: payload.uploadDocSAT,
        uploadDocumentsStatus: STATUS.UPLOAD_DOCUMENTS_FETCHED,
      };
    case ACTIONS.RECEIVE_UPLOAD_DOCUMENTS_SAT_ERROR:
      return {
        ...state,
        uploadDocSAT: payload.error,
        uploadDocumentsStatus: STATUS.UPLOAD_DOCUMENTS_ERROR,
      };

    // Upload documents to SAT switch cases for ACTIONS
    case ACTIONS.REQUEST_UPLOAD_DOCUMENTS_S3:
      return {
        ...state,
        uploadDocumentsStatus: STATUS.UPLOAD_DOCUMENTS_FETCHING,
      };
    case ACTIONS.RECEIVE_UPLOAD_DOCUMENTS_S3:
      return {
        ...state,
        uploadDocS3: payload.uploadDocSAT,
        uploadDocumentsStatus: STATUS.UPLOAD_DOCUMENTS_FETCHED,
      };
    case ACTIONS.RECEIVE_UPLOAD_DOCUMENTS_S3_ERROR:
      return {
        ...state,
        uploadDocS3: payload.error,
        uploadDocumentsStatus: STATUS.UPLOAD_DOCUMENTS_ERROR,
      };

    // Upload documents to EXP
    case ACTIONS.REQUEST_UPLOAD_DOCUMENTS_EXP:
      return {
        ...state,
        uploadDocumentsToEXPStatus: STATUS.UPLOAD_DOCUMENTS_EXP_FETCHING,
      };
    case ACTIONS.RECEIVE_UPLOAD_DOCUMENTS_EXP:
      return {
        ...state,
        uploadDocEXP: payload.uploadDocEXP,
        uploadDocumentsToEXPStatus: STATUS.UPLOAD_DOCUMENTS_EXP_FETCHED,
      };
    case ACTIONS.RECEIVE_UPLOAD_DOCUMENTS_EXP_ERROR:
      return {
        ...state,
        uploadDocEXP: payload.error,
        uploadDocumentsToEXPStatus: STATUS.UPLOAD_DOCUMENTS_EXP_ERROR,
      };

    // GET Agreement Sign Status switch cases
    case ACTIONS.REQUEST_GET_AGREEMENT:
      return {
        ...state,
        postAgreementSignStatus: STATUS.POST_AGREEMENT_UNFETCHED,
        getAgreementSignStatus: {
          ...state.getAgreementSignStatus,
          getStatus: GLOBALTYPES.FETCHING,
          errorData: null,
        },
      };
    case ACTIONS.RECEIVE_GET_AGREEMENT:
      return {
        ...state,
        getAgreementSign: payload.agreementSign,
        getAgreementSignStatus: {
          ...state.getAgreementSignStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_GET_AGREEMENT_ERROR:
      return {
        ...state,
        getAgreementSign: payload.error,
        getAgreementSignStatus: {
          ...state.getAgreementSignStatus,
          getStatus: GLOBALTYPES.ERROR,
          errorData: payload.error,
        },
      };

    // POST signed Document
    case ACTIONS.REQUEST_POST_SIGNED_DOCUMENT:
      return {
        ...state,
        postSignDocumentStatus: GLOBALTYPES.FETCHING,
      };
    case ACTIONS.RECEIVE_POST_SIGNED_DOCUMENT:
      return {
        ...state,
        postSignDocument: payload.documentSign,
        postSignDocumentStatus: GLOBALTYPES.FETCHED,
      };
    case ACTIONS.RECEIVE_POST_SIGNED_DOCUMENT_ERROR:
      return {
        ...state,
        postSignDocument: payload.error,
        postSignDocumentStatus: GLOBALTYPES.ERROR,
      };

    // GET Reenroll Document
    case ACTIONS.REQUEST_GET_REENROLL_DOCUMENT:
      return {
        ...state,
        getReenrollDocStatus: {
          ...state.getReenrollDocStatus,
          getStatus: GLOBALTYPES.FETCHING,
        },
      };
    case ACTIONS.RECEIVE_GET_REENROLL_DOCUMENT:
      return {
        ...state,
        getReenrollDoc: payload.reenrollDocument,
        getReenrollDocStatus: {
          ...state.getReenrollDocStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_GET_REENROLL_DOCUMENT_ERROR:
      return {
        ...state,
        getReenrollDoc: payload.error,
        getReenrollDocStatus: {
          ...state.getReenrollDocStatus,
          getStatus: GLOBALTYPES.ERROR,
        },
      };

    case ACTIONS.RESET_AGREEMENT_DOCS:
      return {
        ...state,
        agreementDocs: [],
        agreementDocsStatus: STATUS.AGREEMENT_DOCS_UNFETCHED,
      };
    case ACTIONS.RESET_GENERAL_AGREEMENT_DOCS:
      return {
        ...state,
        generalAgreementDocs: [],
        generalAgreementDocsStatus: STATUS.GENERAL_AGREEMENT_DOCS_UNFETCHED,
      };
    case ACTIONS.RESET_AGREEMENT_PACKAGE:
      return {
        ...state,
        agreementPackage: {},
        agreementPackageStatus: {
          ...state.agreementPackageStatus,
          getStatus: GLOBALTYPES.UNFETCHED,
          errorData: null,
        },
      };
    case ACTIONS.RESET_CRINIMAL_CONVICTION_DOC:
      return {
        ...state,
        criminalConvictionDoc: {},
        criminalConvictionDocStatus: STATUS.CRIMINAL_CONVICTION_DOC_UNFETCHED,
      };
    case ACTIONS.RESET_NEW_STUDENT_CHECKLIST_DOC:
      return {
        ...state,
        newStudentChecklistDoc: {},
        newStudentChecklistDocStatus: STATUS.NEW_STUDENT_CHECKLIST_DOC_UNFETCHED,
      };
    case ACTIONS.RESET_MA_DISCLOSURE_DOC:
      return {
        ...state,
        maAcknowledgementDoc: {},
        maAcknowledgementStatus: STATUS.MA_DISCLOSURE_DOC_UNFETCHED,
      };
    case ACTIONS.RESET_CA_PERFORMANCE_FACTSHEET_DOC:
      return {
        ...state,
        caAcknowledgementPfsDoc: {},
        caAcknowledgementPfsStatus: STATUS.CA_PERFORMANCE_FACTSHEET_DOC_UNFETCHED,
      };
    // GET esigning url for esign redirect
    case TYPES.REQUEST_POST_ESIGNING_URL:
      return {
        ...state,
        postEsigningUrl: {},
        postEsigningUrlStatus: GLOBALTYPES.FETCHING,
      };
    case TYPES.RECEIVE_POST_ESIGNING_URL:
      return {
        ...state,
        postEsigningUrl: payload,
        postEsigningUrlStatus: GLOBALTYPES.FETCHED,
      };
    case TYPES.ERROR_POST_ESIGNING_URL:
      return {
        ...state,
        postEsigningUrl: payload.error,
        postEsigningUrlStatus: GLOBALTYPES.ERROR,
      };
    // GET esign notification for docuSign redirect
    case TYPES.REQUEST_GET_ESIGN_NOTIFICATION:
      return {
        ...state,
        getEsignNotificationStatus: GLOBALTYPES.FETCHING,
      };
    case TYPES.RECEIVE_GET_ESIGN_NOTIFICATION:
      return {
        ...state,
        getEsignNotification: payload,
        getEsignNotificationStatus: GLOBALTYPES.FETCHED,
      };
    case TYPES.ERROR_GET_ESIGN_NOTIFICATION:
      return {
        ...state,
        getEsignNotification: payload.error,
        getEsignNotificationStatus: GLOBALTYPES.ERROR,
      };
    // get esign transcript task for docu sign
    case TYPES.REQUEST_GET_ESIGN_TRANSCRIPT_TASK:
      return {
        ...state,
        esignTranscriptTask: {},
        esignTranscriptTaskStatus: {
          ...state.esignTranscriptTaskStatus,
          getStatus: GLOBALTYPES.FETCHING,
        },
      };
    case TYPES.RECEIVE_GET_ESIGN_TRANSCRIPT_TASK:
      return {
        ...state,
        esignTranscriptTask: payload,
        esignTranscriptTaskStatus: {
          ...state.esignTranscriptTaskStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case TYPES.ERROR_GET_ESIGN_TRANSCRIPT_TASK:
      return {
        ...state,
        esignTranscriptTask: payload.error,
        esignTranscriptTaskStatus: {
          ...state.esignTranscriptTaskStatus,
          getStatus: GLOBALTYPES.ERROR,
        },
      };
    // delete envelope for docu sign
    case TYPES.REQUEST_DELETE_ESIGN_TRANSCRIPT_ENVELOPE:
      return {
        ...state,
        esignTranscriptTask: {},
        esignTranscriptTaskStatus: {
          ...state.esignTranscriptTaskStatus,
          modifyStatus: GLOBALTYPES.FETCHING,
        },
      };
    case TYPES.RECEIVE_DELETE_ESIGN_TRANSCRIPT_ENVELOPE:
      return {
        ...state,
        esignTranscriptTask: payload,
        esignTranscriptTaskStatus: {
          ...state.esignTranscriptTaskStatus,
          modifyStatus: GLOBALTYPES.FETCHED,
        },
      };
    case TYPES.ERROR_DELETE_ESIGN_TRANSCRIPT_ENVELOPE:
      return {
        ...state,
        esignTranscriptTask: payload.error,
        esignTranscriptTaskStatus: {
          ...state.esignTranscriptTaskStatus,
          modifyStatus: GLOBALTYPES.ERROR,
        },
      };
    // Financial Plan Estimate
    case ACTIONS.REQUEST_FINANCIAL_PLAN_SUMMARY:
      return {
        ...state,
        financialPlanSummary: {},
        financialPlanSummaryStatus: {
          ...state.financialPlanSummaryStatus,
          getStatus: GLOBALTYPES.FETCHING,
        },
      };
    case ACTIONS.RECEIVE_FINANCIAL_PLAN_SUMMARY:
      return {
        ...state,
        financialPlanSummary: payload,
        financialPlanSummaryStatus: {
          ...state.financialPlanSummaryStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_FINANCIAL_PLAN_SUMMARY_ERROR:
      return {
        ...state,
        financialPlanSummary: payload.error,
        financialPlanSummaryStatus: {
          ...state.financialPlanSummaryStatus,
          getStatus: GLOBALTYPES.ERROR,
        },
      };
    case ACTIONS.REQUEST_FINANCIAL_PLAN_SUMMARY_DOWNLOADED:
      return {
        ...state,
        financialPlanSummaryDownloaded: false,
        financialPlanSummaryDownloadedStatus: {
          ...state.financialPlanSummaryDownloadedStatus,
          getStatus: GLOBALTYPES.FETCHING,
        },
      };
    case ACTIONS.RECEIVE_FINANCIAL_PLAN_SUMMARY_DOWNLOADED:
      return {
        ...state,
        financialPlanSummaryDownloaded: payload,
        financialPlanSummaryDownloadedStatus: {
          ...state.financialPlanSummaryDownloadedStatus,
          getStatus: GLOBALTYPES.FETCHED,
        },
      };
    case ACTIONS.RECEIVE_FINANCIAL_PLAN_SUMMARY_DOWNLOADED_ERROR:
      return {
        ...state,
        financialPlanSummaryDownloaded: payload.error,
        financialPlanSummaryDownloadedStatus: {
          ...state.financialPlanSummaryDownloadedStatus,
          getStatus: GLOBALTYPES.ERROR,
        },
      };
    case ACTIONS.SET_FINANCIAL_PLAN_SUMMARY_DOWNLOADED_TRUE:
      return {
        ...state,
        financialPlanSummaryDownloaded: true,
        financialPlanSummaryDownloadedStatus: {
          ...state.financialPlanSummaryDownloadedStatus,
          getStatus: GLOBALTYPES.FETCHED,
          modifyStatus: GLOBALTYPES.UPDATED,
        },
      };
    default:
      return state;
  }
}
