import userManager from '../../../store/domains/auth0/userManager';

export default function LogOutService(client) {
  const {
    REACT_APP_OAUTH_COGNITO_LOGOUT,
    REACT_APP_CAS_SSO_LOGOUT,
    REACT_APP_AUTHDEPOT_LOGOUT,
    REACT_APP_LOGOUT_URL,
    REACT_APP_OAUTH_CLIENT_ID,
    // eslint-disable-next-line no-underscore-dangle
  } = (window.__ENV || {});

  const clearNewUserSessionStorage = () => {
    sessionStorage.removeItem('newUserType');
    // name
    sessionStorage.removeItem('firstName');
    sessionStorage.removeItem('middleName');
    sessionStorage.removeItem('lastName');
    sessionStorage.removeItem('suffix');
    // previous name
    sessionStorage.removeItem('previousFirstName');
    sessionStorage.removeItem('previousMiddleName');
    sessionStorage.removeItem('previousLastName');
    sessionStorage.removeItem('previousSuffix');
    // phone info
    sessionStorage.removeItem('phoneType');
    sessionStorage.removeItem('phoneCountryCode');
    sessionStorage.removeItem('phoneCode');
    sessionStorage.removeItem('phoneCodeAbbreviation');
    sessionStorage.removeItem('phone');
    sessionStorage.removeItem('isConsentExists');
    sessionStorage.removeItem('isTCPAConsent');
    // address
    sessionStorage.removeItem('streetLine');
    sessionStorage.removeItem('country');
    sessionStorage.removeItem('city');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('zipCode');
    // email
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('isUsCitizen');
    sessionStorage.removeItem('isMilitary');
    sessionStorage.removeItem('newUserSubmitted');

    sessionStorage.removeItem('isStartedNewAppAfterExpiry');
    sessionStorage.removeItem('esi');
    sessionStorage.removeItem('skipStartNewAppModal');
    sessionStorage.removeItem('showEditSubmittedAppModal');
    sessionStorage.removeItem('isReEnrollI');

    sessionStorage.removeItem('fromDashboard');
    sessionStorage.removeItem('redirectFromSignSubmit');
    sessionStorage.removeItem('esiIndicator');
    sessionStorage.removeItem('identityVerificationStatus');

    let sessionVarKey;
    for (let i = 0; i < sessionStorage.length; i++) {
      sessionVarKey = sessionStorage.key(i);
      if (sessionVarKey.startsWith('task_')) {
        sessionStorage.removeItem(sessionVarKey);
      }
    }
  };

  const logoutWithCustomRedirect = redirectTo => {
    clearNewUserSessionStorage();
    userManager.revokeAccessToken();
    window.location.replace(`${REACT_APP_OAUTH_COGNITO_LOGOUT}?client_id=${REACT_APP_OAUTH_CLIENT_ID}&logout_uri=${REACT_APP_AUTHDEPOT_LOGOUT}?callback=${redirectTo}`);
    userManager.removeUser();
  };

  const logout = () => {
    clearNewUserSessionStorage();
    userManager.revokeAccessToken();
    window.location.replace(`${REACT_APP_OAUTH_COGNITO_LOGOUT}?client_id=${REACT_APP_OAUTH_CLIENT_ID}&logout_uri=${REACT_APP_AUTHDEPOT_LOGOUT}?callback=${REACT_APP_LOGOUT_URL}`);
    userManager.removeUser();
  };

  return {
    logout,
    logoutWithCustomRedirect,
    clearNewUserSessionStorage,
  };
}
