import retryCallIfNeeded from '../../../restCalls';

const baseEndpointUrl = '/api/iam/v1';

export default function IamService(client) {
  const postValidateUserByUsernameAndPasswordAsync = async (username, password) => (
    retryCallIfNeeded(client, () => client.post(
      `${baseEndpointUrl}/user/${username}/validate`,
      {
        username,
        password,
      },
    )));

  const postValidateUserAsync = async (username, password) => retryCallIfNeeded(client, () => (
    client.post(
      `${baseEndpointUrl}/user/me/validate`,
      {
        username,
        password,
      },
    )));
  const getUsernameAvailabilityAsync = username => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/user/availability?username=${encodeURIComponent(username)}`));
  const getEmailAvailabilityAsync = email => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/user/availability?email=${encodeURIComponent(email)}`));
  const postUserAsync = body => retryCallIfNeeded(client, () => client.post('/api/iam/v1/users', body));
  const getTokeByTokenIdAsync = tokenId => retryCallIfNeeded(client, () => client.get(`${baseEndpointUrl}/token/${tokenId}`));
  const putTokenAsync = (body, tokenId) => retryCallIfNeeded(client, () => client.put(`${baseEndpointUrl}/token/${tokenId}`, body));

  return {
    postValidateUserByUsernameAndPasswordAsync,
    postValidateUserAsync,
    getUsernameAvailabilityAsync,
    getEmailAvailabilityAsync,
    postUserAsync,
    getTokeByTokenIdAsync,
    putTokenAsync,
  };
}
