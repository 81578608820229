const aidYear = window.__ENV.REACT_APP_NEW_AID_YEAR;

export default {
  MONTHS: [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    { value: '3', label: 'March' },
    { value: '4', label: 'April' },
    { value: '5', label: 'May' },
    { value: '6', label: 'June' },
    { value: '7', label: 'July' },
    { value: '8', label: 'August' },
    { value: '9', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
  ],
  // Field Constants
  willReceiveBenefitsField: 'RECV_EMP_BENEFITS',
  willUseBenefitsField: 'USE_EMP_BENEFITS',
  reimburseOrBillField: 'REIM_OR_BILL',
  dollarOrPercentageField: 'BENEFIT_UNIT',
  benefitAmountField: 'BENEFIT_AMOUNT',
  percentageCapField: 'ANNUAL_CAP_AMT_IN_CURRENCY',
  fiscalDayField: 'FISCAL_START_DAY',
  fiscalMonthField: 'FISCAL_START_MONTH',
  // Employer Benefits
  benefitsHeader: 'Employer benefits',
  benefitsSummary: 'Many employers offer a tuition benefit program, so consider using the benefits your employer may offer to help pay your tuition and fees. Speak to your employer to confirm if they offer a tuition assistance program and your eligibility.',
  receiveHeader: 'Do you expect to receive any employer benefits?',
  receiveSummary: 'You can reduce your potential out of pocket if your employer helps pay your tuition.',
  employerReimbursementHeader: 'Will your employer reimburse you, or will we bill your employer directly?',
  employerReimbursementTooltip: 'Check your employer\'s written policy about how education benefits are paid.',
  dollarTooltip: 'Indicate the amount of employer tuition assistance you expect to receive for the academic year.',
  percentageTooltip: 'Indicate the percent of employer tuition assistance you expect to receive for the academic year.',
  percentageCapTooltip: 'Indicate the maximum dollar amount this benefit cannot exceed for the academic year.',
  tuitionHeader: 'Tuition',
  tuitionBenefitsSummary: 'Do you expect to receive any employer tuition benefits?',
  tuitionTooltip: 'Military Tuition Assistance should not be included here.',
  bookHeader: 'Book/resource benefits',
  bookBenefitsSummary: 'Do you expect to receive any book/resource benefits?',
  bookTooltip: 'This covers items required in your coursework, such as books, fees, equipment, and related supplies.',
  tuitionBenefitsPercentageQuestion: 'Is this benefit based on a certain amount or percentage?',
  tuitionBenefitsDollars: 'What is the dollar amount?',
  tuitionBenefitsPercentage: 'What is the tuition benefit percentage?',
  tuitionBenefitsCap: 'What is the annual cap of this tuition benefit? (optional)',
  tuitionBudgetYearHeader: 'When does your employer\'s budget year begin? (optional)',
  tuitionBudgetYearSummary: 'You have the option to change the start date based on your employer\'s budget year. Otherwise, we\'ll default it to the calendar year beginning January 1.',
  tuitionBudgetYearNote: `If applying for financial aid, the amount provided here may be calculated to reflect the expected benefit to be used between June 1, ${aidYear}, and May 31, ${Number(aidYear) + 1}, for financial processing.`,
  // Errors
  tapcapOptInEmptyError: 'You must select Yes or No',
  benefitsDollarsOrPercentageEmptyError: 'You must select Dollars or Percentage',
  benefitsReceiveBenefitsEmptyError: 'You must select Yes or No',
  benefitsReimburseOrBillEmptyError: 'You must select Reimburse me or Bill employer',
  benefitsTuitionDollarEmptyError: 'Please enter the dollar amount of your employer tuition benefit.',
  benefitsBookDollarEmptyError: 'Please enter the dollar amount of your employer tuition benefit.',
  benefitsDollarRegexError: 'Enter only positive whole numbers with decimals and commas',
  tuitionPercentageEmptyError: 'Please enter the percentage of tuition covered by your employer benefit.',
  bookPercentageEmptyError: 'Please enter the percentage of tuition covered by your employer benefit.',
  percentageRegexError: 'Enter only positive whole numbers with decimals and commas',
  percentageTooLargeError: 'You cannot enter a tuition benefit percentage higher than 100 percent.',
  types: {
    json: 'json',
    plusLoan: 'PLUS_LOAN',
    federalLoan: 'FEDERAL_LOAN',
    pellGrants: 'PELL_GRANTS',
    tuition: 'TUITION',
    booksResources: 'BOOKS_RESOURCES',
    affiliationDiscount: 'AFFILIATION_DISCOUNT',
    associationDiscount: 'ASSOCIATION_DISCOUNT',
    tribalBenefit: 'TRIBAL_BENEFIT',
    tribalDiscount: 'TRIBAL_DISCOUNT',
    employerDiscount: 'EMPLOYER_DISCOUNT',
    personal: 'PERSONAL',
  },
  dataPoint: {
    recvUopxEmployee: 'RECV_UOPXEMPLOYEE',
    recvAssociations: 'RECV_ASSOCIATIONS',
    currency: 'CURRENCY',
    percentage: 'PERCENTAGE',
    reimbursement: 'REIMBURSEMENT',
    directBill: 'DIRECT_BILL',
  },
  financialSection: {
    benefits: 'benefits',
    contributions: 'contributions',
    dependencyStatus: 'dependencyStatus',
    discounts: 'discounts',
    military: 'military',
  },
};
