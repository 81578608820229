import axios from 'axios';
import idHeaders from '../interceptors/idHeaders';
import retryCallIfNeeded from '../../restCalls';

const segmentBase = '/application/segment';

const accountVerfication = '/account-verification-status';
const accountVerficationEvents = '/account-verification-status-events';

export default function SegmentService() {
  axios.interceptors.request.use(idHeaders);
  const getAccountVerificationStatus = async personId => retryCallIfNeeded(axios, () => axios
    .get(`${segmentBase}${accountVerfication}?personId=${personId}`));

  const getAccountVerificationStatusEvents = async personId => retryCallIfNeeded(axios, () => axios
    .get(`${segmentBase}${accountVerficationEvents}?personId=${personId}`));

  return {
    getAccountVerificationStatus,
    getAccountVerificationStatusEvents,
  };
}
