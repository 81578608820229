/* action types */
export const REQUEST_POST_USER = 'REQUEST_POST_USER';
export const RECEIVE_POST_USER = 'RECEIVE_POST_USER';
export const REQUEST_POST_USER_ERROR = 'REQUEST_POST_USER_ERROR';
export const REQUEST_VALIDATE_USER = 'REQUEST_VALIDATE_USER';
export const RECEIVE_VALIDATE_USER = 'RECEIVE_VALIDATE_USER';
export const RECEIVE_VALIDATE_USER_ERROR = 'RECEIVE_VALIDATE_USER_ERROR';
export const REQUEST_TOKEN = 'REQUEST_TOKEN';
export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const REQUEST_TOKEN_ERROR = 'REQUEST_TOKEN_ERROR';
export const REQUEST_PUT_TOKEN = 'REQUEST_PUT_TOKEN';
export const RECEIVE_PUT_TOKEN = 'RECEIVE_PUT_TOKEN';
export const REQUEST_PUT_TOKEN_ERROR = 'REQUEST_PUT_TOKEN_ERROR';
